.paymentListCardBox {
  background: #FFFFFF;
  color: #1A1A1A;
  font-size: 14px;
  line-height: 18px;
  padding: 16px;
  border-radius: 8px;
  margin: 16px 0;

  .title {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
  }

  .content {
    .paymentItemBox {
      padding: 12px 0;

      .paymentItemFirst {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .leftBox {
          display: flex;
          align-items: center;
          cursor: pointer;

          &:hover {
            .radio {
              color: #990915;
              background: inherit;
            }
          }

          &.paymentDisabled {
            opacity: 0.5;
            cursor: not-allowed !important;

            &:hover {
              .radio {
                color: #E0E1E5;
                background: #F7F8FC;
              }
            }
          }

          .radio {
            padding: 0 !important;
            margin-right: 12px;
          }

          .payInfoBox {
            display: flex;
            align-items: center;
            font-size: 16px;
            line-height: 20px;

            .imageBox {
              width: 52px;
              height: 36px;
              border-radius: 3px;
              margin-right: 12px;
            }
          }
        }

        .rightBox {
          height: 36px;
          display: flex;
          align-items: center;

          .cardImage {
            width: 42px;
            height: 24px;
            margin-left: 8px;
          }
        }
      }

      .paymentItemSecond {
        margin-top: 4px;
        padding-left: 32px;
      }

      .paymentAccountInfoBox {
        margin-top: 12px;
        margin-left: 32px;
      }

      .accountListbox {
        .radio {
          padding: 0;
          margin-right: 12px;
        }

        .accountItem {}

        .titleBox {
          display: flex;
          align-items: center;
          line-height: 48px;
          cursor: pointer;

          .radio {
            margin-right: 8px;
          }

          &:hover {
            .radio {
              color: #990915;
              background: inherit;
            }
          }

          .brandLogoBox {
            width: 42px;
            margin-right: 8px;
          }
        }

        .contentBox {
          margin-left: 24px;

          .firstBox {
            display: flex;
            align-items: center;
            line-height: 26px;
            margin-bottom: 4px;
            cursor: pointer;

            .checkbox {
              padding: 0 !important;
              margin-right: 4px;
            }

            &:hover {
              .checkbox {
                color: #990915;
                background: inherit;
              }
            }
          }

          .secondBox {
            margin-top: 4px;
            font-size: 14px;
            line-height: 18px;
          }
        }
      }
    }
  }

  .paymentBillingAddressBox {
    // padding-left: 24px;

    .title {
      font-size: 12px;
      line-height: 16px;
      margin-bottom: 4px;
      color: #505259;
      font-weight: 400;
    }

    .detail {
      font-size: 14px;
      line-height: 18px;
      color: #888B94;
      border: 1px solid #E0E1E5;
      padding: 11px 12px;
      display: flex;
      align-items: center;
      border-radius: 4px;
      cursor: pointer;

      .left {
        flex: 1;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
        font-weight: 400;
        display: -webkit-box; //特别显示模式display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        color: #505259;
      }

      .right {
        margin-left: 8px;
      }
    }
  }
}

.payTipDialogContent {
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #1A1A1A;
}