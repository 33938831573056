.cart-container {
    // align-items: center;
    // justify-content: space-between;
    // justify-items: center;

    .sign {
        height: 36px;
        display: flex;
        padding: 8px 12px 8px 12px;
        gap: 6px;
        border-radius: 8px;
        border: 1px solid #FDE2AD;
        background-color: #FFF8EA;
        align-items: center;
        position: sticky;
        margin-bottom: 16px;
        justify-content: space-between;

        .info-left{
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            display: flex;
            color: #C99227;
        }
        .info-right{
            cursor: pointer;
            font-size: 12px;
            font-weight: 400;
            line-height: 12px;
            text-align: left;
            color: #1869F5;
        }
    }

    .divider{
        margin: 0;
    }

    .box-container{
        display: flex;
        margin-bottom: 32px;
        justify-content: space-between;
        .left{
            // padding-left: 16px;
            // padding-right: 16px;
            // background-color: white;
            width: 880px;
            border-radius: 8px;
        }
        .right{
            height: fit-content;
            background-color: white;
            width: 368px;
            top: 0;
            position: sticky;
            border-radius: 8px;
        }
    }

    .guarantee-service {
        height: 124px;

        .title {
            font-size: 14px;
            font-weight: 700;
            line-height: 14px;
            text-align: left;
            color: #e92121;
        }

        .img {}
    }
    .sign-box{
        flex-direction: column; /* 改为垂直排列 */
        align-items: center;
        justify-content: center;
        display: flex;
        height: 100%;
        background: white;
        border-radius: 8px;
    }
    .empty-box{
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: #C2C4CC;
        flex-direction: row; /* 保持图标和文字水平排列 */
    }

    .sign-in{
        height: 28px;
        padding: 8px 12px;
        gap: 4px;
        border-radius: 4px;
        border: 0.5px solid #888B94;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        text-align: center;
        color: #1A1A1A;
    }
}